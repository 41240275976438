'use client'

import useTranslation from 'next-translate/useTranslation'
import React from 'react'
import { Carousel } from 'react-responsive-carousel'

import 'react-responsive-carousel/lib/styles/carousel.min.css'

import NextImage from '@/components/NextImage'

const hangoutExamples = {
  en: [
    'https://imagedelivery.net/yK3h-AoWKOTnYW-6OY420g/6500400a-f02c-4c57-89de-9c95a2492700/public',
    'https://imagedelivery.net/yK3h-AoWKOTnYW-6OY420g/3d6cd69d-5a6d-41d0-200e-138453f74500/public',
    'https://imagedelivery.net/yK3h-AoWKOTnYW-6OY420g/fd6afc56-5091-4f49-ac85-7549d057da00/public',
  ],
  es: [
    'https://imagedelivery.net/yK3h-AoWKOTnYW-6OY420g/dfdebc28-5e11-4874-e74f-ead4a0e09a00/public',
    'https://imagedelivery.net/yK3h-AoWKOTnYW-6OY420g/ef09d4a8-044d-4c5c-7785-22bb25300000/public',
    'https://imagedelivery.net/yK3h-AoWKOTnYW-6OY420g/0e31a816-94da-4708-5650-cda00fabf200/public',
  ],
}

function EmptyHangout() {
  const { t, lang } = useTranslation()

  const previews = lang === 'en' ? hangoutExamples['en'] : hangoutExamples['es']
  return (
    <div className="flex flex-col justify-between lg:flex-row">
      <div className="flex flex-1 flex-col justify-between px-4 lg:px-0">
        <p className="text-text-800 mb-4 text-2xl font-medium lg:text-3xl">
          {t(`There are still no Hangouts in your location!`)}
        </p>
        <p className="text-text-800 text-xl font-normal">{t(`hangout_empty_para_1`)}</p>
        <p className="text-text-800 mb-4 mt-4 text-xl font-normal">{t(`hangout_empty_para_2`)}</p>
      </div>
      <div className="relative flex flex-1 justify-center">
        <div className="h-[300px] w-[300px]">
          <Carousel autoPlay infiniteLoop showStatus={false}>
            {previews.map((image, index) => (
              <div key={index} className="h-[300px] w-[300px]">
                <NextImage
                  src={image}
                  alt="post imag"
                  fill={true}
                  className="rounded-[20px] bg-cover"
                  imgClassName="object-fit"
                />
              </div>
            ))}
          </Carousel>
        </div>
      </div>
    </div>
  )
}

export default EmptyHangout
