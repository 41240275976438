import React from 'react'

import BasicBadgeRounded from '@/assets/icons/basic-badge-rounded.png'
import PartnerBadgeRounded from '@/assets/icons/partner-badge-rounded.png'
import SocialBadgeRounded from '@/assets/icons/social-badge-rounded.png'
import TrialperiodBadgeRounded from '@/assets/icons/trialperiod-badge-rounded.png'
import VipBadgeRounded from '@/assets/icons/vip-badge-rounded.png'

import NextImage from './NextImage'

import { User } from '@/types'

function UserAvatar(props: {
  user: Pick<User, 'id' | 'name' | 'firstname' | 'lastname' | 'photoUrl' | 'meta'>
  size: 'mini' | 'small' | 'medium' | 'large'
}) {
  const { user, size = 'small' } = props

  // size can be 'mini' `small` `medium`, `large`

  if (!user) {
    return null
  }

  let avatarText = ''
  let photoUrl = user?.photoUrl || user?.image || null
  const meta = user?.meta || {}

  if (photoUrl && photoUrl?.includes('default-user-image.png')) {
    photoUrl = null
  }

  avatarText = user?.firstname?.charAt(0) + user?.lastname?.charAt(0)

  const { isSubscribed, subscriptionType = 'free', subscriptionStatus = null } = meta

  const isPartner =
    (user?.meta?.badges && Array.isArray(user?.meta?.badges) && user?.meta?.badges?.includes('partner')) || false

  let badgeIcon = null

  let imageStyle = { width: 64, height: 64 }
  let badgeStyle = { width: 21, height: 21 }
  let avatarTextStyle = { fontSize: 31 }
  let iconSize = 24

  if (size === 'mini') {
    imageStyle = { width: 34, height: 34 }
    badgeStyle = { width: 21, height: 21 }
    avatarTextStyle = { fontSize: 18 }
    iconSize = 24
  }

  if (size === 'medium') {
    imageStyle = { width: 64, height: 64 }
    badgeStyle = { width: 21, height: 21 }
    avatarTextStyle = { fontSize: 31 }
    iconSize = 24
  }

  if (size === 'large') {
    imageStyle = { width: 97, height: 97 }
    badgeStyle = { width: 30, height: 30 }
    avatarTextStyle = { fontSize: 50 }
    iconSize = 34
  }

  if (subscriptionType === 'basic' || subscriptionType === 'ybasic') {
    badgeIcon = (
      <NextImage
        alt="basic"
        className="absolute right-0 top-0"
        src={BasicBadgeRounded}
        width={iconSize}
        height={iconSize}
        style={badgeStyle}
      />
    )
  }

  if (subscriptionType === 'social') {
    badgeIcon = (
      <NextImage
        alt="social"
        className="absolute right-0 top-0"
        src={SocialBadgeRounded}
        width={iconSize}
        height={iconSize}
        style={badgeStyle}
      />
    )
  }

  if (subscriptionType === 'vip') {
    badgeIcon = (
      <NextImage
        alt="vip"
        className="absolute right-0 top-0"
        src={VipBadgeRounded}
        width={iconSize}
        height={iconSize}
        style={badgeStyle}
      />
    )
  }

  if (subscriptionStatus === 'trialing') {
    badgeIcon = (
      <NextImage
        alt="trialing"
        className="absolute right-0 top-0"
        src={TrialperiodBadgeRounded}
        width={iconSize}
        height={iconSize}
        style={badgeStyle}
      />
    )
  }

  if (!isSubscribed && isPartner) {
    badgeIcon = (
      <NextImage
        alt="partner"
        className="absolute right-0 top-0"
        src={PartnerBadgeRounded}
        width={iconSize}
        height={iconSize}
        style={badgeStyle}
      />
    )
  }

  if (size === 'mini') {
    badgeIcon = null
  }

  return (
    <div className="relative" style={imageStyle}>
      {badgeIcon}
      <div className="bg-secondary-50 flex items-center justify-center rounded-full" style={imageStyle}>
        {photoUrl ? (
          <NextImage
            src={photoUrl}
            alt={avatarText}
            {...imageStyle}
            className="flex justify-center overflow-hidden rounded-full"
            imgClassName="rounded-full object-cover"
          />
        ) : (
          <p className="text-secondary-400 font-roboto" style={avatarTextStyle}>
            {(avatarText || '')?.toUpperCase()}
          </p>
        )}
      </div>
    </div>
  )
}

export default UserAvatar
