'use client'
import useTranslation from 'next-translate/useTranslation'
import { useState } from 'react'

import { useTracking } from '@/hooks/useTracking'

import Button from '@/components/buttons/Button'
import ImageModal from '@/components/ImageModal'

function PurposeHangout({ labal }) {
  const { t, lang } = useTranslation()
  const tracking = useTracking()

  const [showPopup, setShowPopup] = useState(false)

  const handleClick = () => {
    //{ evt: 'See All Hangouts Clicked', data: undefined }
    setShowPopup(true)
    tracking.track({ evt: 'Propose Hangout clicked', data: undefined })
  }

  return (
    <>
      {showPopup && <ImageModal type="download-app-pop-up" handleCloseModal={() => setShowPopup(false)} />}
      <Button
        type="button"
        variant="hangout"
        size="base"
        className="w-full justify-center lg:w-auto"
        leftIcon={() => (
          <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
            <path
              d="M13.4369 11.644L18.6512 11.644C19.3613 11.644 19.9369 12.2197 19.9369 12.9297C19.9369 13.6398 19.3613 14.2155 18.6512 14.2155L13.4369 14.2155L13.4369 19.4298C13.4369 20.1478 12.8549 20.7298 12.1369 20.7298C11.4189 20.7298 10.8369 20.1478 10.8369 19.4298L10.8369 14.2155L5.58923 14.2155C4.8976 14.2155 4.33691 13.6548 4.33691 12.9631C4.33691 12.2346 4.9275 11.644 5.65602 11.644H10.8369V6.42976C10.8369 5.71179 11.4189 5.12976 12.1369 5.12976C12.8549 5.12976 13.4369 5.71179 13.4369 6.42976L13.4369 11.644Z"
              fill="#3d3d3d"
            />
          </svg>
        )}
        onClick={handleClick}>
        {labal}
      </Button>
    </>
  )
}

export default PurposeHangout
