'use client'
import { Field as FormikField, Formik } from 'formik'
import { get } from 'lodash'
import useTranslation from 'next-translate/useTranslation'
import { useState } from 'react'

import clsxm from '@/lib/clsxm'
import { useTracking } from '@/hooks/useTracking'

import Button from '@/components/buttons/Button'
import { TextField } from '@/components/form/TextField'
import WebLink from '@/components/links/WebLink'

import { api } from '@/services/api'

function NewsLetter() {
  const { t, lang } = useTranslation()
  const tracking = useTracking()

  const [emailId, setEmailId] = useState(false)

  const handleSubmitForm = async (values, actions) => {
    actions.setSubmitting(true)
    try {
      const res = await api.subscribeNewsletter(values.email)

      if (res.kind === 'ok') {
        const id = get(res.data, 'id') || 0
        if (id) {
          setEmailId(true)
        }
      }

      tracking.track({ evt: 'subscribe_to_newsletter' })
      tracking.track({ evt: 'Newsletter Subscribed', data: { email: values.email } })
    } catch (err) {
      const errors = _.get(err, 'errors') || {}
      const errMessage = _.get(err, 'message') || 'Unable to process request.'
      if (!_.isEmpty(errors)) {
        actions.setErrors({
          ...errors,
        })
      }
    }

    actions.setSubmitting(false)
  }
  return (
    <section className=" bg-tertiaryYellow-400 mx-4 mb-10 mt-10 flex flex-col rounded-[20px] lg:container lg:mx-auto lg:flex-row">
      <div className={clsxm('mx-6 flex flex-1 flex-col pb-11 pt-11', emailId && `lg:ml-16`)}>
        <h2 className={clsxm('text-4xl font-medium lg:text-left', emailId && 'text-center')}>
          {!emailId ? t('Do you want to know more about Vermut?') : t("Great, we'll send you more information!")}
        </h2>

        <div className={`${emailId ? 'sm:items-center' : 'sm:items-start'}`}>
          <Formik
            initialValues={{
              email: '',
            }}
            validate={(values) => {
              const errors = {}
              if (!values.email) {
                errors.email = t('Required')
              } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
                errors.email = t('Invalid email address')
              }
              return errors
            }}
            onSubmit={handleSubmitForm}>
            {(formikBag) => {
              return (
                <div className="mt-3 flex flex-col">
                  <h2
                    className={`${
                      emailId ? 'text-center text-4xl font-normal lg:text-left' : 'text-2xl font-medium'
                    }  `}>
                    {emailId ? t('Start trying activities') : t('Receive more information in your email')}
                  </h2>
                  <div className="mt-3 w-full">
                    {!emailId && (
                      <form onSubmit={formikBag.handleSubmit}>
                        <div className="relative flex flex-row items-start lg:w-96">
                          <FormikField
                            containerClass="w-full"
                            className="flex w-full flex-col items-start rounded-full border border-gray-100 bg-gray-100 px-2 py-3 pr-20 shadow-lg focus:border-none focus:outline-none focus:ring-0 lg:w-96 lg:rounded-bl-full lg:rounded-tl-full"
                            type="email"
                            name="email"
                            minLength="6"
                            maxLength="40"
                            component={TextField}
                            placeholder={t('Write your email') + '...'}
                            validate-conditions="email|mandatory"
                            friendlyname="Correo Electrónico"
                            replace-conditions="email"
                            required
                          />
                          <button
                            type="submit"
                            // onClick={}
                            disabled={formikBag.isSubmitting}
                            className="bg-primary-800 from-primary-400 hover:bg-primary-900 absolute right-0 mt-0 self-start rounded-full bg-gradient-to-b px-10 py-3 text-base leading-relaxed text-white shadow-lg transition-all duration-300 hover:shadow-2xl disabled:opacity-30 lg:-right-7">
                            {t('Send')}
                          </button>
                        </div>
                      </form>
                    )}
                  </div>
                </div>
              )
            }}
          </Formik>

          {/* {!emailId && (
          <div className="pt-5">
            <p className=" text-2xl font-bold">{t('Or')}</p>
          </div>
        )} */}
        </div>
      </div>

      {emailId ? (
        <div className="mb-6 flex items-center justify-center lg:mb-0 lg:mr-16">
          <WebLink href={`/${lang}/auth/login`}>
            <button className="text-primary-700 min-w-[200px] cursor-pointer rounded-2xl border bg-white px-7 py-3 text-center text-2xl font-bold tracking-widest shadow-lg transition-all duration-300 hover:border-amber-500 hover:shadow-2xl lg:px-12">
              {t('Login')}
            </button>
          </WebLink>
        </div>
      ) : (
        <div className="lg:bg-tertiaryYellow-50 flex min-h-[350px] flex-1 flex-col items-center justify-center bg-[url('/images/neswletter-bg.svg')] bg-cover bg-center bg-no-repeat bg-origin-border px-3 pt-3 lg:min-h-fit lg:rounded-bl-[117px] lg:rounded-br-[20px] lg:rounded-tl-[117px] lg:rounded-tr-[20px]">
          <h1 className="max-w-[270px] text-center text-[32px] font-bold lg:max-w-full">
            {t('Start trying activities')}
          </h1>
          <div className="mt-5 lg:pb-9">
            <WebLink href={`/${lang}/auth/login`}>
              <Button type="button" variant="primary" size="base" className="w-56 justify-center">
                {t(`Login`)}
              </Button>
            </WebLink>
          </div>
        </div>
      )}
    </section>
  )
}

export default NewsLetter
