'use client'
import useTranslation from 'next-translate/useTranslation'
import React from 'react'

import { getMobileOperatingSystem } from '@/lib/helper'
import { useTracking } from '@/hooks/useTracking'

import Button from '@/components/buttons/Button'
import NextImage from '@/components/NextImage'

import AppGIF from '@/assets/home/app.gif'
import Booked from '@/assets/home/icons40px/PNG/booked.png'
import Calendar from '@/assets/home/icons40px/PNG/calendar.png'
import Categories from '@/assets/home/icons40px/PNG/categories.png'
import Chat from '@/assets/home/icons40px/PNG/chat.png'
import Community from '@/assets/home/icons40px/PNG/comunity.png'
import Hangouts from '@/assets/home/icons40px/PNG/hangouts@2x.png'
import Noticias from '@/assets/home/icons40px/PNG/noticias.png'
import Profile from '@/assets/home/icons40px/PNG/profile.png'
import Search from '@/assets/home/icons40px/PNG/search.png'
import Subscription from '@/assets/home/icons40px/PNG/subscription.png'
import Wallet from '@/assets/home/icons40px/PNG/wallet.png'
import Appstore from '@/assets/icons/appstore.png'
import Playstore from '@/assets/icons/playstore.png'

function OurApp() {
  const { t } = useTranslation()

  const tracking = useTracking()

  const handleDownloadApp = () => {
    tracking.track({
      evt: 'app_download_begin',
      data: { funnelStep: '1' },
    })

    const appLink = getMobileOperatingSystem()
    window.open(appLink)
  }

  const funListData = [
    {
      title: t('Calendario'),
      icon: Calendar,
    },
    {
      title: t('Comunidad'),
      icon: Community,
    },
    {
      title: t('Monedero'),
      icon: Wallet,
    },
    {
      title: t('Chats'),
      icon: Chat,
    },
    {
      title: t('Suscripciones'),
      icon: Subscription,
    },
    {
      title: t('Gestión de Reserva'),
      icon: Booked,
    },
    {
      title: t('Perfil'),
      icon: Profile,
    },
    {
      title: t('Categorías'),
      icon: Categories,
    },
    {
      title: t('Noticias'),
      icon: Noticias,
    },
    {
      title: t('Búsqueda'),
      icon: Search,
    },
    {
      title: t('Quedadas'),
      icon: Hangouts,
    },
  ]

  return (
    <section className="bg-secondary-50 mx-auto mt-0 w-full pb-24 lg:container lg:rounded-[20px] sm:px-20 lg:mt-40">
      <div className="container mx-auto">
        <div className="items-center md:flex">
          <div className="my-auto block py-4 sm:pt-24 md:mr-4 md:w-1/2 md:rounded">
            <div className="pt-4 text-center text-4xl font-semibold md:text-left">
              <h2 className="text-black-80 text-3xl lg:text-5xl">{t('Our app is more')}</h2>
            </div>
            <div className="text-center text-7xl font-extrabold sm:text-8xl md:text-left">
              <h3 className=" text-6xl lg:text-8xl">{t('Fun!')}</h3>
            </div>

            <div>
              <div className="flex justify-center pt-10 sm:justify-start">
                <div className="relative h-12  w-40 pr-1 sm:pr-4">
                  <NextImage src={Playstore} alt="Playstore icon" />
                </div>

                <div className=" relative h-12 w-40 pl-1 sm:pl-4">
                  <NextImage src={Appstore} alt="Appstore icon" />
                </div>
              </div>

              <div className="flex justify-center py-10 sm:justify-start">
                <Button
                  type="button"
                  variant="primary"
                  size="base"
                  className="justify-center"
                  onClick={handleDownloadApp}>
                  {t('Download the App')}
                </Button>
              </div>
            </div>
          </div>
          <div className="flex justify-end py-10 sm:-mb-48 sm:py-0 md:order-2 md:w-1/2 lg:-mt-96">
            <div className="sm:h-128 sm:w-128 h-96 w-96 lg:h-[614px] lg:w-[614px]">
              <NextImage
                placeholder="blur"
                blurDataURL="iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mP8v1LnPwAHWALVX891zgAAAABJRU5ErkJggg=="
                src={AppGIF}
                alt="App gif"
              />
            </div>
          </div>
        </div>
        <div className="">
          <div className="py-6 text-center text-2xl font-normal md:text-left">
            <p className="text-3xl font-normal">{t('More than 10 features that make your experience unique!')}</p>
          </div>
          <div className="">
            <div className="flex flex-wrap px-4 lg:px-0">
              {funListData.map((item, index) => {
                return (
                  <div
                    onClick={handleDownloadApp}
                    key={`fun-item-${index}`}
                    className="mr-3 mb-3 mt-3 flex flex-grow cursor-pointer items-center justify-center rounded-xl bg-white px-2 py-1 text-center font-semibold tracking-widest shadow-lg sm:flex-grow-0">
                    <div className="relative h-10 w-10">
                      <NextImage src={item.icon} alt={item.title} />
                    </div>
                    <span className="px-2 text-sm sm:text-base">{t(item.title)}</span>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default OurApp
