'use client'
import { truncate } from 'lodash'
import useTranslation from 'next-translate/useTranslation'
import { useState } from 'react'

import NextImage from '@/components/NextImage'

import ImageModal from './ImageModal'
import WebLink from './links/WebLink'
import UserAvatar from './UserAvatar'

import { PostDetails } from '@/types'

function HomePost({ post }: { post: PostDetails }) {
  const { t, lang } = useTranslation()

  const [showDownlaodApp, setShowDownlaodApp] = useState(false)

  const handleClick = (e) => {
    setShowDownlaodApp(true)
  }

  const imageUrl = post.media && post.media.length > 0 ? post.media[0] : null
  const body = imageUrl ? truncate(post.body, { length: 50 }) : post.body
  const name = post.user.firstname + ' ' + post.user.lastname
  const likesCount = post.likesCount || 0
  const commentCount = post.commentsCount || 0

  return (
    <>
      {showDownlaodApp && <ImageModal type="download-app-pop-up" handleCloseModal={() => setShowDownlaodApp(false)} />}
      <div className="border-text-500 relative mr-3 block w-[278px] cursor-pointer rounded-[20px] border px-4 py-4">
        <WebLink href={`/${lang}/posts/${post.id}`} className=" ">
          <div className="flex min-h-[50px] flex-row items-center">
            <UserAvatar user={post.user} size="mini" />
            <span className="font-roboto ml-2 line-clamp-2 font-medium">{name}</span>
          </div>
          <div className="mb-3 mt-2 min-h-[56px]">
            <p className="font-roboto text-text-500 line-clamp-6 text-lg">{body}</p>
          </div>
          <div className="mb-12 flex items-center justify-center">
            {imageUrl && (
              <NextImage
                className="h-[127px] w-[236px] rounded-2xl object-cover"
                alt="post image"
                src={imageUrl}
                width={236}
                height={127}
                // style={{ width: '72px', height: '72px', objectFit: 'fill' }}
                // unoptimized
              />
            )}
          </div>
        </WebLink>
        <div className="absolute bottom-0 mb-1 flex w-[246px]  flex-row items-center justify-between">
          <div>
            {likesCount > 0 ? (
              <p className="text-text-500 flex cursor-pointer flex-col items-start" onClick={handleClick}>
                <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M26.4562 7.81509C25.2179 6.43391 23.551 5.67188 21.6935 5.67188C19.074 5.67188 17.4547 7.24357 16.5022 8.5295C16.2641 8.86289 16.0735 9.19628 15.883 9.52967C15.6925 9.19628 15.502 8.86289 15.2639 8.5295C14.359 7.24357 12.692 5.67188 10.0725 5.67188C8.21508 5.67188 6.54813 6.43391 5.30983 7.81509C4.16678 9.14865 3.5 10.9109 3.5 12.7683C3.5 14.8163 4.30966 16.7214 6.02424 18.7693C7.5483 20.5791 9.78677 22.4842 12.3586 24.6751C13.3112 25.4847 14.3113 26.342 15.3591 27.2469L15.4068 27.2946C15.5496 27.4374 15.6925 27.4851 15.883 27.4851C16.0735 27.4851 16.2164 27.4374 16.3593 27.2946L16.4069 27.2469C17.4547 26.342 18.4549 25.4847 19.4074 24.6751C21.9793 22.4842 24.1701 20.5791 25.7418 18.7693C27.4564 16.7214 28.2661 14.8163 28.2661 12.7683C28.2184 10.9109 27.5993 9.14865 26.4562 7.81509Z"
                    stroke="#3D3D3D"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                  />
                </svg>
                {likesCount} {t('Likes')}
              </p>
            ) : null}
          </div>
          <div>
            {commentCount > 0 ? (
              <p className="text-text-500 flex cursor-pointer flex-col items-end" onClick={handleClick}>
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M6.01772 22.5541L7.01251 22.4521L6.98682 22.2016L6.8456 21.9932L6.01772 22.5541ZM4.90817 26.5105L4.01859 26.0537H4.01859L4.90817 26.5105ZM4.57938 27.4097L5.22971 26.6501L5.22971 26.6501L4.57938 27.4097ZM9.93297 26.2069L10.4356 25.3424L10.0028 25.0907L9.542 25.2865L9.93297 26.2069ZM27.0206 15.9023C27.0206 21.9338 22.0932 26.8358 15.9996 26.8358V28.8358C23.184 28.8358 29.0206 23.0521 29.0206 15.9023H27.0206ZM15.9996 4.96875C22.0932 4.96875 27.0206 9.87073 27.0206 15.9023H29.0206C29.0206 8.7524 23.184 2.96875 15.9996 2.96875V4.96875ZM4.97852 15.9023C4.97852 9.87073 9.90591 4.96875 15.9996 4.96875V2.96875C8.81514 2.96875 2.97852 8.7524 2.97852 15.9023H4.97852ZM6.8456 21.9932C5.66624 20.2525 4.97852 18.1583 4.97852 15.9023H2.97852C2.97852 18.5716 3.79375 21.0544 5.18985 23.115L6.8456 21.9932ZM5.79775 26.9672C6.25129 26.084 7.20131 24.293 7.01251 22.4521L5.02294 22.6561C5.14111 23.8083 4.52638 25.0648 4.01859 26.0537L5.79775 26.9672ZM5.22971 26.6501C5.41972 26.8127 5.50336 27.0126 5.53483 27.1608C5.56351 27.2958 5.5527 27.3997 5.54709 27.4406C5.53658 27.5174 5.51853 27.5466 5.54141 27.4901C5.58276 27.3881 5.66264 27.2304 5.79775 26.9672L4.01859 26.0537C3.90165 26.2814 3.77094 26.5339 3.68783 26.7389C3.64847 26.8361 3.58971 26.9931 3.56559 27.1692C3.54874 27.2922 3.49562 27.7983 3.92905 28.1694L5.22971 26.6501ZM9.542 25.2865C8.30457 25.8121 7.17652 26.2209 6.30521 26.4353C5.86634 26.5433 5.53482 26.5911 5.30805 26.5943C5.03948 26.598 5.09795 26.5373 5.22971 26.6501L3.92905 28.1694C4.36847 28.5456 4.93572 28.5997 5.33581 28.5941C5.7777 28.588 6.27473 28.5024 6.78307 28.3774C7.80621 28.1256 9.04729 27.6696 10.3239 27.1273L9.542 25.2865ZM15.9996 26.8358C13.9678 26.8358 12.0676 26.2913 10.4356 25.3424L9.43032 27.0714C11.3599 28.1933 13.6059 28.8358 15.9996 28.8358V26.8358Z"
                    fill="#3D3D3D"
                  />
                </svg>
                {commentCount} {t(`Comments`)}
              </p>
            ) : null}
          </div>
        </div>
      </div>
    </>
  )
}

export default HomePost
