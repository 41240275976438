import { getIn } from 'formik'

export function TextField(props) {
  const {
    field,
    form,
    // variant,
    // disabled,
    // placeholder,
    // type,
    // name,
    onChange,
    containerClass = '',
    ...rest
  } = props

  const hasError = !!(getIn(form.touched, field.name) && getIn(form.errors, field.name))
  const errText = hasError ? getIn(form.errors, field.name) : ''

  return (
    <div className={`formik-field-container ${containerClass} ${hasError ? 'has-error' : ''}`}>
      <input
        className="mt-2 h-12 w-1/2 rounded-lg border border-gray-800 bg-transparent px-4 focus:border-gray-200 focus:bg-white focus:shadow-lg focus:outline-none"
        // onWheel={(event) => {
        //   // event.preventDefault();
        //   event.target.blur()
        // }}

        {...field}
        {...rest}
      />

      {hasError ? <span className="text-red-600">{errText} </span> : null}
    </div>
  )
}
