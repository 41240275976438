'use client'
import useTranslation from 'next-translate/useTranslation'

import { getMobileOperatingSystem } from '@/lib/helper'
import { useTracking } from '@/hooks/useTracking'

import Button from '@/components/buttons/Button'

function SubscribeToApp() {
  const { t } = useTranslation()

  const tracking = useTracking()

  const handleDownloadApp = () => {
    tracking.track({
      evt: 'app_download_begin',
      data: { funnelStep: '1' },
    })

    const appLink = getMobileOperatingSystem()
    window.open(appLink)
  }

  return (
    <div className="my-10 flex items-center justify-center">
      <Button type="button" variant="primary" size="base" className="w-full justify-center lg:max-w-[300px]" onClick={handleDownloadApp}>
        {t('Try Vermut for free')}
      </Button>
    </div>
  )
}

export default SubscribeToApp
