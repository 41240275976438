'use client'
import { useEffect, useRef } from 'react'

import { useTracking } from '@/hooks/useTracking'

import { TrackableEvent } from '@/types'

type PageTrackingProps = {
  events?: TrackableEvent[]
}

function PageTracking(props: PageTrackingProps) {
  const { events } = props
  const tracking = useTracking()

  const trackingRef = useRef<boolean>(false)

  useEffect(() => {
    if (trackingRef.current) {
      return
    }

    if (typeof window === 'undefined') {
      return
    }

    trackingRef.current = true

    tracking.track({ evt: 'navigation', data: { url: window.location.href } })
    tracking.track({ evt: 'Page View', data: { url: window.location.href } })

    if (events && events.length > 0) {
      for (const evt of events) {
        tracking.track(evt)
      }
    }

    console.log(`PageTracking mounting!`)
    return () => console.log(`And now PageTracking unmounting`)
  }, [])

  return null
}

export default PageTracking
