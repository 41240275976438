'use client'
import useTranslation from 'next-translate/useTranslation'
import React from 'react'

import clsxm from '@/lib/clsxm'
import { getMobileOperatingSystem } from '@/lib/helper'
import { useTracking } from '@/hooks/useTracking'

import Button from '@/components/buttons/Button'
import NextImage from '@/components/NextImage'

import Appstore from '@/assets/match-making/appstore.png'
import FrontPreview from '@/assets/match-making/front-preview.png'
import Playstore from '@/assets/match-making/playstore.png'

function DownloadApp({ isUserLoggedIn }: { isUserLoggedIn: boolean }) {
  const { t } = useTranslation()

  const tracking = useTracking()

  const handleDownloadApp = () => {
    tracking.track({
      evt: 'app_download_begin',
      data: { funnelStep: '1' },
    })

    const appLink = getMobileOperatingSystem()
    window.open(appLink)
  }

  return (
    <section className={clsxm('pb-10', isUserLoggedIn && 'bg-secondary-50')}>
      <div className={clsxm('container mx-auto  px-6 lg:flex lg:justify-center lg:px-0')}>
        <div className="bg-matchMaking-primary max-w-128 flex  flex-col items-center justify-between rounded-[20px] px-7 py-4 xl:max-w-fit xl:flex-row xl:px-14 xl:py-10">
          <div className="mb-6 xl:mb-0 xl:mr-12">
            <NextImage
              src={FrontPreview}
              alt="ChartImage icon"
              className="h-[101px] w-[113px] place-self-center xl:h-[152px] xl:w-[169px]"
            />
          </div>
          <div>
            <div>
              <p className="font-roboto mr-1 inline text-center text-xl font-medium text-white xl:mr-0 xl:block xl:text-left xl:text-3xl">
                {t('Make friends with your ideal group!')}
              </p>
              <p className="font-roboto inline text-center text-xl font-normal text-white xl:block xl:text-left xl:text-3xl">
                {t('Vermut AI generates friendships with similar interests in your city')}.
              </p>
            </div>
            <div className="flex flex-col xl:mt-8 xl:flex-row">
              <p className="hidden text-3xl font-medium text-white xl:mr-5 xl:block">{t('Available in')}</p>
              <div onClick={handleDownloadApp} className="flex cursor-pointer justify-center py-4 xl:py-0">
                <NextImage width={128} height={36} src={Playstore} alt="Playstore icon" />
                <div className="w-4"></div>
                <NextImage width={128} height={36} src={Appstore} alt="Appstore icon" />
              </div>
            </div>
          </div>

          <div className="px-4 py-4 xl:py-3">
            <div className="text-nowrap">
              <Button
                type="button"
                variant="white"
                size="base"
                // className="text-nowrap"
                onClick={handleDownloadApp}>
                {t('Download the App')}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default DownloadApp
