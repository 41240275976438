'use client'
import useTranslation from 'next-translate/useTranslation'

import clsxm from '@/lib/clsxm'

import NextImage from '@/components/NextImage'

import { SubscriptionInfo, SubscriptionType } from '@/types'

type SubscriptionCardProps = {
  // type: 'free' | SubscriptionType
  // actual?: boolean
  subscription: SubscriptionInfo
}

const badges = {
  free: require(`@/assets/icons/free-badge.png`),
  basic: require(`@/assets/icons/basic-badge.png`),
  ybasic: require(`@/assets/icons/basic-badge.png`),
  ybasic24: require(`@/assets/icons/basic-badge.png`),
  usbasic: require(`@/assets/icons/basic-badge.png`),
  usybasic24: require(`@/assets/icons/basic-badge.png`),
  social: require(`@/assets/icons/social-badge.png`),
  vip: require(`@/assets/icons/vip-badge.png`),
}

function SubscriptionCard(props: SubscriptionCardProps) {
  const { t } = useTranslation()

  const { subscription } = props

  const textColor = subscription.colors.text
  let name = t(subscription.displayName)

  if (subscription.price > 0) {
    name += ' ' + subscription.priceStr
  }

  const badgeUrl = badges[subscription.type]
  return (
    <div className="relative">
      <div className="absolute right-4 top-1">
        <NextImage
          src={badgeUrl}
          alt={subscription.type + ' subscription'}
          className="self-center"
          width={56}
          height={56}
        />
      </div>
      <svg width={165} height={116} viewBox="0 0 165 116" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x={0.915039} y={0.632812} width={163.792} height={115} rx={11.1423} fill={subscription.colors.bg} />
        {subscription.price > 0 ? (
          <text x="21" y="72" fill={textColor} fontSize="12" fontFamily="roboto" fontWeight="500">
            {t('Membresías')}
          </text>
        ) : null}
        <text x="21" y="95" fill={textColor} fontSize="22" fontFamily="roboto" fontWeight="500">
          {name}
          {subscription.price > 0 ? <tspan fontSize="12">/ {subscription.isYearly ? t(`año`) : t(`mes`)}</tspan> : null}
        </text>

        <mask
          id="a"
          style={{
            maskType: 'alpha',
          }}
          maskUnits="userSpaceOnUse"
          x={0}
          y={0}
          width={165}
          height={116}>
          <rect x={0.915039} y={0.632812} width={163.792} height={115} rx={11.1423} fill="#2A6FBF" />
        </mask>
        <g mask="url(#a)">
          <ellipse cx={132.951} cy={7.08681} rx={60.7256} ry={63.954} fill={subscription.colors.primary}></ellipse>
        </g>
        <g clipPath="url(#clip0_433_6270)">
          {subscription?.displayTag ? (
            <path d="M40.257.633h19.55L.914 53.233V34.97L40.257.633z" fill={subscription.colors.displayTag} />
          ) : null}
          {subscription?.displayTag ? (
            <text
              x="10"
              y="10"
              fill="#3d3d3d"
              textAnchor="center"
              fontSize="9"
              fontFamily="roboto"
              fontWeight="700"
              transform="translate(-7, 45) rotate(-45)">
              {t(subscription.displayTag)}
            </text>
          ) : null}
        </g>
        <defs>
          <clipPath id="clip0_433_6270">
            <path fill="#fff" transform="translate(.915 .633)" d="M0 0H71.3643V55.4361H0z" />
          </clipPath>
        </defs>
      </svg>
    </div>
  )
}

export default function InfoCards(props: { item: SubscriptionInfo }) {
  const { t } = useTranslation()
  const { item } = props
  return (
    <>
      <div className="bg-background-10 border-text-400 mx-2 my-2 flex flex-col rounded-[20px] border px-6 py-4 lg:mx-0 lg:max-w-[300px] 2xl:max-w-[350px]">
        <p className="text-center">{t(item.name)}</p>
        <div className="relative mt-3 flex h-40 w-full items-center justify-center">
          <SubscriptionCard subscription={item} />
        </div>
        <div className="py-4">
          <ul>
            {item.benefits?.map((benefit, index) => {
              return (
                <li className="flex flex-row pb-4" key={`benefit-${item.type}-${index}`}>
                  <div className="pr-1">
                    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M4.81775 14.1658L9.31775 18.6626L18.0178 9.96875" stroke="#00B70E" />
                    </svg>
                  </div>
                  <p
                    className={clsxm(
                      'leading-snug',
                      index === 0 && 'font-medium',
                      index === 1 && item.key === 'vip' && 'font-medium',
                    )}>
                    {t(benefit)}
                  </p>
                </li>
              )
            })}
          </ul>
        </div>
      </div>
    </>
  )
}
