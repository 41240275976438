'use client'
import useTranslation from 'next-translate/useTranslation'
import { useState } from 'react'

import { useTracking } from '@/hooks/useTracking'
import { useUser } from '@/hooks/useUser'

import ImageModal from '@/components/ImageModal'
import NextImage from '@/components/NextImage'
import { withNoSSR } from '@/components/withNoSSR'

import CreditIcon from '@/assets/icons/all.png'

function HomeUserInfo() {
  const { t } = useTranslation()
  const tracking = useTracking()

  const { subscriptionEnabled, profile } = useUser()
  const [showPopup, setShowPopup] = useState(false)

  const handleClick = () => {
    //{ evt: 'See All Hangouts Clicked', data: undefined }
    setShowPopup(true)
    // tracking.track({ evt: 'Propose Hangout clicked', data: undefined })
  }

  const showCredits = subscriptionEnabled()

  return (
    <>
      {showPopup && <ImageModal type="download-app-pop-up" handleCloseModal={() => setShowPopup(false)} />}
      <div
        className="flex max-w-[232px] cursor-pointer justify-between gap-3 rounded-[40px] bg-white p-[10px]"
        onClick={handleClick}>
        <div className="min-w-[100px] rounded-[44px] bg-[#C5DFC7] p-[10px] text-center">
          <span className="text-2xl font-medium">Chat</span>
        </div>
        {showCredits && (
          <div className="bg-cream-300 min-w-[100px] rounded-[44px] px-[10px] text-center">
            <span className="text-base font-medium">{t(`Credits`)}</span>
            <div className="flex flex-row items-center justify-center">
              <NextImage src={CreditIcon} width={24} height={24} alt="credit icon" />
              <span className="ml-1 text-2xl font-medium">
                {profile && profile?.creditBalance ? profile?.creditBalance / 100 : 0}
              </span>
              &nbsp;
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default withNoSSR(HomeUserInfo)
