'use client'
import useTranslation from 'next-translate/useTranslation'
import { useState } from 'react'

import Button from '@/components/buttons/Button'
import WebLink from '@/components/links/WebLink'
import LocationModal from '@/components/LocationModal'

import { LocationInfo } from '@/types'

function FeaturedActivityHeader({ location, locationSlug }: { location: LocationInfo; locationSlug: string }) {
  const { t, lang } = useTranslation()

  const [isLocationModalOpen, setIsLocationModalOpen] = useState(false)

  let allLink = `/${lang}/${location.citySlug}/activities`

  if (!location.featured) {
    allLink += '?inperson=1'
  }

  return (
    <>
      <LocationModal
        // locationInfo={locationInfo}
        isOpen={isLocationModalOpen}
        onClose={() => setIsLocationModalOpen(false)}
      />
      <div className="flex items-center justify-between px-4">
        <div className="flex flex-col lg:flex-row lg:items-center">
          <h2 className="text-text-800 text-3xl font-medium sm:flex-none lg:mr-4 lg:text-5xl">{t(`Activities in`)}</h2>
          <div
            onClick={() => setIsLocationModalOpen(true)}
            className="border-text-800 flex cursor-pointer flex-row items-center justify-center rounded-[55px] border-2 px-1 py-2 lg:px-3 lg:py-3">
            <p className="text-3xl font-medium leading-none lg:text-5xl">{location?.cityName}</p>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              className="lg:h-10 lg:w-10"
              viewBox="0 0 22 22"
              fill="none">
              <path
                d="M5.22266 9.80078L10.3718 14.9499C10.7046 15.2827 11.2441 15.2827 11.5769 14.9499L16.726 9.80078"
                stroke="#3D3D3D"
                strokeWidth="2.27227"
                strokeLinecap="round"
              />
            </svg>
          </div>
        </div>
        <div className="cursor-pointer">
          <WebLink href={allLink}>
            <Button type="button" variant="primary" size="md">
              {t(`All`)}
            </Button>
          </WebLink>
        </div>
      </div>
    </>
  )
}

export default FeaturedActivityHeader
